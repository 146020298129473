import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import * as actions from "../../../../redux/auth/authActions";

function UserProfileDropdown(props) {
  const [showUserMenu, setShowUserMenu] = useState(false);

  const uiEvents = {
    handleOpenUserMenu: () => {
      setShowUserMenu(true);
    },
    handleCloseUserMenu: (action) => {
      setShowUserMenu(false);
      if (action === "logout") {
        actions.logout();
      }
    },
  };

  return (
    <div>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={uiEvents.handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={showUserMenu}
          onClose={() => {
            uiEvents.handleCloseUserMenu("");
          }}
        >
          <MenuItem
            onClick={() => {
              uiEvents.handleCloseUserMenu("");
            }}
          >
            <Typography textAlign="center">Profile</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              uiEvents.handleCloseUserMenu("");
            }}
          >
            <Typography textAlign="center">Account</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              uiEvents.handleCloseUserMenu("logout");
            }}
          >
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
          {/* ))} */}
        </Menu>
      </Box>

      {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ width: 56, height: 56 }} /> */}
    </div>
  );
}

export default UserProfileDropdown;
