import { createSlice } from "@reduxjs/toolkit";

const initMushroomEditorState = {
  mushroomEditorList: [],
  countMushroomEditor: 0,
  countMushroomEditorApproved: 0,
  statisticList: [],
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const mushroomEditorSlice = createSlice({
  name: "mushroomEditor",
  initialState: initMushroomEditorState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    mushroomEditorFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.mushroomEditorList = data.items || [];

      if (data.objects) {
        state.countMushroomEditor = data.objects.total;
      } else if (data.total) {
        state.countMushroomEditor = data.total;
      } else {
        state.countMushroomEditor = 0;
      }
    },

    mushroomEditorApprovedCountFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;

      if (data) {
        state.countMushroomEditorApproved = data;
      } else {
        state.countMushroomEditorApproved = 0;
      }
    },

    fieldsEditActionFetched: (state, action) => {
      const { data } = action.payload;
      state.fieldsEditAction = data || [];
    },

    statisticFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;

      if (data) {
        state.statisticList = data;
      } else {
        state.statisticList = 0;
      }
    }
  },
});
