import axios from "axios";

const USER_LOGIN_URL = window.API_BASE_URL + "/auth/login";
const USER_REGISTER_URL = window.API_BASE_URL + "/auth/register";
const USER_PROFILE_URL = window.API_BASE_URL + "/user/profile";

export const login = (params) => {
  const requestBody = { ...params };

  return axios({
    method: "post",
    url: USER_LOGIN_URL,
    data: requestBody,
  });
};

export const register = (params) => {
  const requestBody = { ...params };

  return axios({
    method: "post",
    url: USER_REGISTER_URL,
    data: requestBody,
  });
};

export const loadProfile = (params) => {
  const requestBody = { ...params };

  return axios({
    method: "get",
    url: USER_PROFILE_URL,
    data: requestBody,
  });
}
