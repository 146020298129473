import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./Login";

function AuthPage(props) {
  return (
    <Switch>
      <Route path={"/auth/login"} component={Login} />
    </Switch>
  );
}

export default AuthPage;
