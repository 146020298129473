import { logout } from "./auth/authActions";

export const setUpAxios = (axios, store) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (request) {
      const {
        auth: { user },
      } = store.getState();
      if (user) {
        request.headers.Authorization = `Bearer ${user.access_token}`;
      }
      // Do something before request is sent
      return request;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (response.status === 500) {
        logout();
      }
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
};
