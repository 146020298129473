import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from "react-router-dom";
import AuthPage from "../redux/auth/page/AuthPage";
import "./App.css";
import Layout from "./layout/Layout";
import RootRoutes from "./modules/RootRoutes";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { getConfig, setLang } from "./common/i18n/common";

function App() {
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));
  const language = getConfig();

  const isAuthenticated = useMemo(() => {
    if (user?.success) {
      return true;
    } else {
      return false;
    }
  }, [user]);

  useEffect(() => {
    if (!language) {
      setLang("en");
    }
  }, [language]);

  return (
    <Switch>
      {!isAuthenticated ? (
        <>
          <Route>
            <AuthPage />
          </Route>
          {<Redirect to={"/auth/login"} />}
        </>
      ) : (
        <Redirect from="/auth/login" to="/" />
      )}

      {isAuthenticated ? (
        <Layout>
          <RootRoutes />
        </Layout>
      ) : (
        <>
          <Redirect from="/auth/login" to="/" />
        </>
      )}
    </Switch>
  );
}

export default App;
