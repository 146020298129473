import { authSlice, callTypes } from "./authSlice";
import * as requestFromServer from "./authApi";
import { setStorage } from "../../app/common/helper/LocalStorageHelper";
const { actions } = authSlice;

export const login = (params) => (dispatch) => {
  dispatch(actions.startCall);

  return requestFromServer
    .login(params)
    .then((res) => {
      const data = res.data;
      if (data?.success) {
        dispatch(actions.logged({ data }));
      }
      return data;
    })
    .catch((err) => {
      dispatch(actions.catchError);
    })
    .finally(() => {
      dispatch(actions.endCall);
    });
};

export const register = (params) => (dispatch) => {
  dispatch(actions.startCall());

  return requestFromServer
    .register(params)
    .then((res) => {
      const data = res.data;
      dispatch(actions.registered({ data }));
    })
    .catch((err) => {
      dispatch(actions.catchError);
    })
    .finally(() => {
      dispatch(actions.endCall);
    });
};

export const loadProfile = (params) => (dispatch) => {
  dispatch(actions.startCall);

  return requestFromServer
    .loadProfile(params)
    .then((res) => {
      const data = res.data.data;
      dispatch(actions.setProfile({ data}));
    })
    .catch((err) => {
      dispatch(actions.catchError);
    })
    .finally(() => {
      dispatch(actions.endCall);
    });
}
export const logout = () => {
  setStorage("persist:user", null);
  window.location = "/";
};
