const I18N_CONFIG_KEY = "language";

export function getConfig() {
  const language = localStorage.getItem(I18N_CONFIG_KEY);
  if (language) {
    return language;
  } else {
    return "";
  }
}

export function setLang(language) {
  localStorage.setItem(I18N_CONFIG_KEY, language);
  window.location.reload();
}
