import { createSlice } from "@reduxjs/toolkit";

const initErrorLogState = {
  errorLogList: [],
  errorLogChart: [],
  errorLogBarChart: [],
  countErrorLogList: 0,
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const errorLogSlice = createSlice({
  name: "errorLog",
  initialState: initErrorLogState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    errorLogListFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.errorLogList = data.items || [];

      if (data.objects) {
        state.countErrorLogList = data.objects.total;
      } else if (data.total) {
        state.countErrorLogList = data.total;
      } else {
        state.countErrorLogList = 0;
      }
    },

    errorLogChartFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.errorLogChart = data || [];
    },

    errorLogBarChartFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.errorLogBarChart = data || [];
    },

  },
});
