import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useState } from "react";
import { getConfig, setLang } from "./common";

// en
import enTotalPlantMessages from "app/modules/TotalPlant/i18n/en.json";

function I18nProvider({ children }) {
  const initLocale = "en";

  const [locale, setLocale] = useState(initLocale);

  const langConfig = getConfig();

  const messages = {
    en: enTotalPlantMessages,
  };

  useEffect(() => {
    if (langConfig) {
      setLocale(langConfig);
    } else {
      setLang(initLocale);
    }
  }, [langConfig]);

  return (
    <IntlProvider messages={{ ...messages[locale] }} locale={locale}>
      {children}
    </IntlProvider>
  );
}

export default I18nProvider;
