import { createSlice } from "@reduxjs/toolkit";

const initWishListState = {
  wishList: [],
  countWishList: 0,
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const wishListSlice = createSlice({
  name: "wishList",
  initialState: initWishListState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }

    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }

    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }

    },

    wishListFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.wishList = data.items || [];

      if (data.objects) {
        state.countWishList = data.objects.total;
      } else if (data.total) {
        state.countWishList = data.total;
      } else {
        state.countWishList = 0;
      }
    },
  },
});
