import { createSlice } from "@reduxjs/toolkit";

const initUserSlice = {
  userList: [],
  userCount: 0,
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  action: "action",
  list: "list",
};

export const userSlice = createSlice({
  name: "users",
  initialState: initUserSlice,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    userListFetched: (state, action) => {
      const { data } = action.payload;
      state.userList = data.items || [];

      if (data.objects) {
        state.userCount = data.objects.total;
      } else if (data.total) {
        state.userCount = data.total;
      } else {
        state.userCount = 0;
      }
    },
  },
});
