import styled from "@emotion/styled";
import { Tooltip, tooltipClasses } from "@mui/material";
import React from "react";

export const TooltipBar = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.08)",
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 8,
  },
}));
