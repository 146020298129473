import { createSlice } from "@reduxjs/toolkit";

const initDeviceSlice = {
  deviceList: [],
  deviceCount: 0,
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  action: "action",
  list: "list",
};

export const deviceSlice = createSlice({
  name: "device",
  initialState: initDeviceSlice,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    deviceListFetched: (state, action) => {
      const { data } = action.payload;
      state.deviceList = data.items || [];

      if (data.objects) {
        state.deviceCount = data.objects.total;
      } else if (data.total) {
        state.deviceCount = data.total;
      } else {
        state.deviceCount = 0;
      }
    },
  },
});
