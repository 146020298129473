export function getCurrentUrl(location) {
  return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(location, url) {
  const current = getCurrentUrl(location);
  if (!current || !url) {
    return false;
  }

  return !!(current === url || current.startsWith(url + "/"));
}
