import { createSlice } from "@reduxjs/toolkit";

const initDiagnoseEditorState = {
  diagnoseEditorList: [],
  countDiagnoseEditor: 0,
  countDiagnoseEditorApproved: 0,
  statisticList: [],
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const diagnoseEditorSlice = createSlice({
  name: "diagnoseEditor",
  initialState: initDiagnoseEditorState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    diagnoseEditorFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.diagnoseEditorList = data.items || [];

      if (data.objects) {
        state.countDiagnoseEditor = data.objects.total;
      } else if (data.total) {
        state.countDiagnoseEditor = data.total;
      } else {
        state.countDiagnoseEditor = 0;
      }
    },

    diagnoseEditorApprovedCountFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;

      if (data) {
        state.countDiagnoseEditorApproved = data;
      } else {
        state.countDiagnoseEditorApproved = 0;
      }
    },

    fieldsEditActionFetched: (state, action) => {
      const { data } = action.payload;
      state.fieldsEditAction = data || [];
    },

    statisticFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;

      if (data) {
        state.statisticList = data;
      } else {
        state.statisticList = 0;
      }
    }
  },
});
