import React, { useEffect, useState } from 'react'
import Aside from "./components/aside/Aside";
import Header from "./components/header/Header";
import { useDispatch } from 'react-redux'
import * as actions from "../../redux/auth/authActions";
import { Helmet } from 'react-helmet'

function Layout({ children }) {
  const dispatch = useDispatch();
  const [showAsideMenu, setShowAsideMenu] = useState(false);

  const uiEvents = {
    toggleAsideMenu: () => {
      setShowAsideMenu(!showAsideMenu);
    },
  };
  const favicon = window.ENV === "prod" ? "favicon_prod.ico" : "favicon_dev.ico";

  useEffect(() => {
    dispatch(actions.loadProfile()).then((res) => {
    });
  }, [])

  return (
    <>
      <div className="d-flex flex-root">
        <Helmet>
          <link rel="icon" href={"/" + favicon} />
        </Helmet>
        <Header showAsideMenu={showAsideMenu} toggleMenu={uiEvents.toggleAsideMenu} />
        <div className="w-100 flex-wrapper d-flex justify-content-between align-items-center">
          <Aside showAsideMenu={showAsideMenu} />
          <div
            className={`w-100 h-100 bg-light ${
              showAsideMenu ? "flex-content-aside" : "flex-content"
            }`}
          >
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
