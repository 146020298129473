import { createSlice } from "@reduxjs/toolkit";

const initTotalPlantState = {
  totalPlantList: [],
  fieldsEditAction: {},
  countSpecies: 0,
  listLoading: false,
  actionsLoading: false,
  error: null,
  openNoti: false,       // Thêm trường openNoti
  notiMessage: ""        // Thêm trường notiMessage
};

export const callTypes = {
  action: "action",
  list: "list",
};

export const totalPlantSlice = createSlice({
  name: "totalPlant",
  initialState: initTotalPlantState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    showSnackbar: (state, action) => {
      const { open, message } = action.payload;
      state.openNoti = open;
      state.notiMessage = message;
    },

    totalPlantListFetched: (state, action) => {
      const { data } = action.payload;
      state.totalPlantList = data.items || [];

      if (data.objects) {
        state.countSpecies = data.objects.total;
      } else if (data.total) {
        state.countSpecies = data.total;
      } else {
        state.countSpecies = 0;
      }
    },

    fieldsEditActionFetched: (state, action) => {
      const { data } = action.payload;
      state.fieldsEditAction = data || [];
    },

    editorListActionFetched: (state, action) => {
      const { data } = action.payload;
      state.editorList = data || [];
    },
  },
});
