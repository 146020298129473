import React from "react";
import UserProfileDropdown from "./UserProfileDropdown";
import logo from "../../../common/assets/images/logo.png";
import { useHistory } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

function Header({ toggleMenu, showAsideMenu }) {
  const history = useHistory();

  return (
    <div className="header shadow d-flex justify-content-between align-items-center">
      <div className="flex-row-1 logo-header d-flex justify-content-between align-items-center">
        <img
          src={logo}
          className="logo"
          alt="logo"
          onClick={() => {
            history.push("/");
          }}
        />
        <span onClick={toggleMenu} className="cursor-pointer">
          {showAsideMenu ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
        </span>
      </div>
      <UserProfileDropdown />
    </div>
  );
}

export default Header;
