import React, { useState } from 'react'
import { Formik, Field } from 'formik'
import { Input } from "../../../app/common/controls/form/Input";
import { Button } from "react-bootstrap";
import * as actions from "../authActions";
import { useDispatch } from "react-redux";
import { Avatar, Box, Typography } from '@mui/material'
import Alert from 'react-bootstrap/Alert';

function Login() {
  const dispatch = useDispatch();

  const [errorMessages, setErrorMessages] = useState(null);
  const [showError, setShowError] = useState(false);
  const requestLogin = (formValues) => {
    dispatch(actions.login(formValues)).then((res) => {
      if (!res?.data?.success) {
        setErrorMessages("Email or password invalid!");
        setShowError(true);
      } else {
        setErrorMessages(null);
        setShowError(false);
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{/* <LockOutlinedIcon /> */}</Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              requestLogin(values);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="email" component={Input} label="Email" placeholder="Email" />
                <Field
                  name="password"
                  component={Input}
                  label="Password"
                  type="password"
                  placeholder="Password"
                />
                <Alert key={"danger"} variant={"danger"} show={showError} className="mt-3">
                  { errorMessages }
                </Alert>
                <div className="d-flex justify-content-center">
                  <Button type="submit" className="btn-primary mt-3 align-items-center">
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
}

export default Login;
