import { createSlice } from "@reduxjs/toolkit";

const initToxicState = {
  toxicList: [],
  countToxic: 0,
  countToxicApproved: 0,
  statisticList: [],
  listLoading: false,
  actionsLoading: false,
  error: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const toxicEditorSlice = createSlice({
  name: "toxicEditorSlice",
  initialState: initToxicState,
  reducers: {
    catchError: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      const { callType } = action.payload;
      if (callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    toxicFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.toxicList = data.items || [];

      if (data.objects) {
        state.countToxic = data.objects.total;
      } else if (data.total) {
        state.countToxic = data.total;
      } else {
        state.countToxic = 0;
      }
    },

    toxicApprovedCountFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;

      if (data) {
        state.countToxicApproved = data;
      } else {
        state.countToxicApproved = 0;
      }
    },

    fieldsEditActionFetched: (state, action) => {
      const { data } = action.payload;
      state.fieldsEditAction = data || [];
    },

    statisticFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;

      if (data) {
        state.statisticList = data;
      } else {
        state.statisticList = 0;
      }
    }
  },
});
